export default (store) => {
  store.subscribe((mutation) => {
    if (mutation.type === 'SET_USERNAME') {
      localStorage.setItem('username', mutation.payload);
    }
    if (mutation.type === 'SET_USER') {
      localStorage.setItem('username', mutation.payload.username);
      localStorage.setItem('displayName', mutation.payload.displayName);
    }
  });
};
