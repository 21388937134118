import Vue from 'vue'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import './components';
import './plugins';
import store from './store';
import router from './router';
import App from './App.vue'
import { auth } from '@/services/firebase';

Vue.config.productionTip = false
Vue.use(Buefy, {
  defaultIconPack: 'fa',
  defaultTooltipType: 'is-dark',
});

// mount the app after we know from firebase
let app = null;
auth.onAuthStateChanged(() => {
  if (!app) {
    new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  }
});
