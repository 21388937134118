import Vue from 'vue';
import AsyncComputed from 'vue-async-computed';
import { firestorePlugin } from 'vuefire';
import VueApexCharts from 'vue-apexcharts';
import Buefy from 'buefy';
import 'buefy/dist/buefy.css';

Vue.use(AsyncComputed);
Vue.use(firestorePlugin);
Vue.use(VueApexCharts);
Vue.use(Buefy, {
  defaultIconPack: 'fa',
  defaultTooltipType: 'is-dark',
});
