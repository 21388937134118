<template lang="pug">
  #app
    b-sidebar(
      v-if="isLoggedIn"
      :open="sidebarOpen"
      fullheight
      overlay
      @close="closeSidebar"
      )
      sidebar
    .app-sidebar.is-hidden-mobile.is-hidden-tablet-only.is-hidden-desktop-only(v-if="isLoggedIn")
      sidebar
    .app-content
      navbar(v-if="isLoggedIn")
      router-view.p-6-desktop.p-5
</template>

<script>
import { auth, db } from '@/services/firebase';
import { mapGetters } from 'vuex';

import Navbar from '@/components/Navbar';
import Sidebar from '@/components/Sidebar';

export default {
  name: 'App',
  components: {
    Navbar,
    Sidebar,
  },
  data() {
    return {
      open: false
    }
  },
  computed: {
    ...mapGetters(['sidebarOpen', 'version', 'releaseNotes']),
    isLoggedIn() {
      return auth.currentUser !== null;
    },
  },
  watch: {
    '$route.path': {
      handler(val) {
        this.closeSidebar();
      },
    },
    isLoggedIn: {
      immediate: true,
      handler(val) {
        if (val) {
          db.collection('pull-requests-too')
            .onSnapshot(querySnapshot => this.$store.dispatch('setPullRequests', querySnapshot.docs.map(doc => doc.data())));

          db.collection('admins')
            .onSnapshot(querySnapshot => this.$store.dispatch('setAdminUsers', querySnapshot.docs.map(doc => doc.data())));
          
          db.collection('config').doc('version')
            .onSnapshot(versionDoc => this.$store.dispatch('setAppVersion', versionDoc.data()));

          db.collection('feedback')
            .onSnapshot(querySnapshot => this.$store.dispatch('setFeedbackItems', querySnapshot.docs.map(doc => doc.data())));
        }
      }
    },
    version: {
      immediate: true,
      handler(newVal, oldVal) {
        if (oldVal && oldVal !== newVal) {
          const base = '<span>New PaxHub Updates are available.</span><br>';
          const message = this.releaseNotes.length ? base + this.releaseNotes : base;
          this.$buefy.snackbar.open({
            actionText: 'Refresh',
            type: 'is-primary',
            indefinite: true,
            message,
            onAction: () => window.location.reload(),
          });
        }
      },
    },
  },
  async mounted() {
    const username = localStorage.getItem('username');
    const displayName = localStorage.getItem('displayName');

    this.$store.dispatch('setUser', { username, displayName });
  },
  methods: {
    closeSidebar() {
      this.$store.dispatch('setSidebar', false);
    },
  },
};
</script>

<style lang="scss">
@import '~@/assets/all.scss';

#app {
  display: flex;
  height: 100vh;
  background: $white;
  .app-sidebar {
    overflow-y: auto;
    overflow-x: hidden;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 300px;
    background: $white-ter;
    border-right: 1px solid $grey-lighter;
    z-index: 10;
    &.collapsed {
      flex-basis: auto;
      overflow: unset;
    }
  }

  .app-content {
    flex-grow: 1;
    background: $white-bis;
    overflow-y: scroll;
    // chrome OSx Lion thing (causes the scrollbar to be hidden)
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, .4);
      box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    }
  }
}
</style>
