 <template lang="pug">
    .p-html-editor
      editor(
        v-model="theValue"
        api-key="ge06pud5v4d6vvpdkbk0fkizsgd8r3zcdich26svzc6tx4l1"
        model-events="change keydown blur focus paste"
        :initial-value="initialValue"
        :init="init"
        )
      .has-text-right
        .help {{ `${theValue.length} / ${maxLength}` }}
 </template>

 <script>
 import Editor from '@tinymce/tinymce-vue';

 export default {
   name: 'PHtmlEditor',
   components: {
     Editor,
   },
   props: {
     value: {
       type: String,
       required: true,
     },
     height: {
       type: Number,
       default: 250,
     },
     maxLength: {
       type: Number,
       default: 250,
     },
     showMenuBar: {
       type: Boolean,
       default: false,
     },
     plugins: {
       type: Array,
       default: () => ([
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount'
        ]),
     },
     toolbar: {
       type: String,
       default: () => ('undo redo | formatselect | bold italic backcolor | \ alignleft aligncenter alignright alignjustify | \ bullist numlist outdent indent | removeformat | help'),
     },
     initialValue: {
       type: String,
       default: '',
     },
   },
   data() {
      return {
        init: {
          height: this.height,
          menubar: this.showMenuBar,
          plugins: this.plugins,
          toolbar: this.toolbar,
        },
        theValue: '',
      };
    },
    watch: {
      value: {
        immediate: true,
        handler(value) {
          this.theValue = value;
        },
      },
      theValue: {
        handler(theValue) {
          const emitValue = theValue.length >= this.maxLength ? theValue.slice(0, this.maxLength) : theValue;
          this.theValue = emitValue;
          this.$emit('input', emitValue);
        },
      },
    },
 };
</script>
