export default {
  state: {
    displayName: null,
    username: null,
    pullRequests: [],
    sidebarOpen: false,
    admins: [],
    version: null,
    releaseNotes: '',
    feedback: [],
  },
  mutations: {
    SET_USER(state, user) {
      state.username = user.username;
      state.displayName = user.displayName;
    },
    SET_USERNAME(state, payload) {
      state.username = payload;
    },
    SET_ALL_PULL_REQUESTS(state, payload) {
      state.pullRequests = payload;
    },
    SET_ADMIN_USERS(state, payload) {
      state.admins = payload;
    },
    SET_APP_VERSION(state, { number, releaseNotes}) {
      state.version = number;
      state.releaseNotes = releaseNotes;
    },
    SET_SIDEBAR(state, payload) {
      state.sidebarOpen = payload
    },
    SET_FEEDBACK_ITEMS(state, payload) {
      state.feedback = payload;
    },
  },
  actions: {
    setUsername(context, payload) {
      context.commit('SET_USERNAME', payload)
    },
    setPullRequests(context, payload) {
      context.commit('SET_ALL_PULL_REQUESTS', payload);
    },
    setAdminUsers(context, payload) {
      context.commit('SET_ADMIN_USERS', payload);
    },
    setAppVersion(context, payload) {
      context.commit('SET_APP_VERSION', payload);
    },
    setUser(context, user) {
      context.commit('SET_USER', user);
    },
    setSidebar(context, payload) {
      context.commit('SET_SIDEBAR', payload);
    },
    setFeedbackItems(context, payload) {
      context.commit('SET_FEEDBACK_ITEMS', payload);
    },
  },
  getters: {
    version: state => state.version,
    releaseNotes: state => state.releaseNotes,
    username: state => state.username,
    adminUsernames: state => state.admins.map(a => a.username),
    isAdminUser: (state, getters) => getters.adminUsernames.includes(getters.username),
    sidebarOpen: state => state.sidebarOpen,
    allPullRequests: state => state.pullRequests || [],
    releasePullRequests: state => state.pullRequests.filter(pr => pr.destinationBranch.includes('release')) || [],
    cometPullRequests: state => state.pullRequests.filter(pr => pr.destinationBranch.includes('Comet')) || [],
    myPullRequests: (state, getters) => state.pullRequests.filter(pr => pr.user.login === getters.username) || [],
    repos: state => [...new Set(state.pullRequests.map(pr => pr.repository) || [])],
    pullRequestCreators: state => [...new Set(state.pullRequests.map(pr => pr.user.login) || [])],
    feedback: state => state.feedback,
  },
};
