<template lang="pug">
  .sidebar.is-fullheight.has-background-white
    router-link(to="/")
      img.my-5.ml-4(src="logo.png" width="200px")
    b-menu
      b-menu-list
        b-menu-item.my-2(
          to="/"
          tag="router-link"
          :active="$route.path === '/'"
          )
          template(slot="label")
            b-icon.ml-2(icon="github" pack="fab" size="is-medium")
            span.ml-2 Pull Requests
        b-menu-item.my-2(
          to="/leaderboard"
          tag="router-link"
          :active="$route.path === '/leaderboard'"
          )
          template(slot="label")
            b-icon.ml-2(icon="medal" size="is-medium")
            span.ml-2 Leaderboard
        b-menu-item.my-2(
          v-if="hasEnoughHackathonTime"
          to="/statistics"
          tag="router-link"
          :active="$route.path === '/statistics'"
          )
          template(slot="label")
            b-icon.ml-2(icon="chart-line" size="is-medium")
            span.ml-2 Statistics
        b-menu-item.my-2(
          to="/user-profile"
          tag="router-link"
          :active="$route.path === '/user-profile'"
          )
          template(slot="label")
            b-icon.ml-2(icon="user-circle" size="is-medium")
            span.ml-2 Profile
        b-menu-item.my-2.repo-links
          template(slot="label" slot-scope="props")
            b-icon.ml-2(icon="link" size="is-medium")
            span.ml-2 Repo Links
            b-icon(class="ml-auto" :icon="props.expanded ? 'angle-down' : 'angle-up'")
          b-menu-item(
            v-for="repo in repos"
            tag="a"
            :key="repo"
            :label="repo"
            target="_blank"
            :href="`https://github.com/pax8/${repo}`"
            )
        b-menu-item.my-2(
          v-if="isAdminUser"
          to="/admin"
          tag="router-link"
          :active="$route.path === '/admin'"
          )
          template(slot="label")
            b-icon.ml-2(icon="user-secret" size="is-medium")
            span.ml-2 Admin
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Sidebar',
  data() {
    return {
      hasEnoughHackathonTime: false,
    };
  },
  computed: {
    ...mapGetters(['repos', 'isAdminUser']),
  },
};
</script>

<style lang="scss">
.sidebar {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 5em;
  .menu-list {
    a {
      display: flex;
      align-items: center;
    }
    .router-link-exact-active {
      background-color: $primary;
      color: $white;
    }
    .repo-links {
      a {
        &.is-active {
          background-color: unset;
          color: unset;
        }
      }
    }
  }
}
</style>