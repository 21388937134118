import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/functions';

const instance = firebase.initializeApp({
  apiKey: "AIzaSyBt8HEXXRuxzOOIHzmmUsOqlc2vt0iqtJQ",
  authDomain: "github-plus.firebaseapp.com",
  databaseURL: "https://github-plus.firebaseio.com",
  projectId: "github-plus",
  storageBucket: "github-plus.appspot.com",
  messagingSenderId: "84168553770"
});

const db = instance.firestore();
const auth = instance.auth();
const functions = instance.functions();

const notifySlackPullRequestChannel = functions.httpsCallable('notifySlackPullRequestChannel');
const processAllToo = functions.httpsCallable('processAllToo');

export {
  db,
  auth,
  functions,
  notifySlackPullRequestChannel,
  processAllToo,
};