<template lang="pug">
  .navbar.is-fullwidth
    .level.is-mobile.is-fullwidth
      .level-left
        .level-item.is-hidden-widescreen
          b-navbar-item(tag="div")
            b-button(
              icon-left="bars"
              type="is-white"
              rounded
              @click.native="openSidebar"
              )
          b-navbar-item
            router-link(to="/")
              img(
                src="logo.png"
                title="profile"
                )
      .level-right
        .level-item
          figure.image.is-32x32.is-clickable
            img.avatar(
              :src="user.photoURL"
              @click="$router.push('/user-profile')"
              )
          b-navbar-item(tag="div")
            .buttons
              b-tooltip(label="Send Feedback" position="is-bottom")
                b-button(
                  icon-left="comment-alt"
                  size="is-small"
                  @click="feedbackComponentIsActive = true"
                  )
              b-button(
                icon-left="sign-out-alt"
                size="is-small"
                label="Logout"
                @click="logout"
                )
      feedback-modal(
        :is-active="feedbackComponentIsActive"
        @close="feedbackComponentIsActive = false"
        )
</template>

<script>
import { auth } from '@/services/firebase';

import FeedbackModal from '@/components/feedback/FeedbackModal.vue';

export default {
  name: 'Navbar',
  components: {
    FeedbackModal,
  },
  data() {
    return {
      feedbackComponentIsActive: false,
    };
  },
  computed: {
    user() {
      return auth.currentUser;
    },
  },
  methods: {
    logout() {
      auth.signOut()
        .then(() => {
          this.$store.dispatch('setUser', { username: '', displayName: '' });
          this.$router.replace('login');
        });
    },
    openSidebar() {
      this.$store.dispatch('setSidebar', true);
    }
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  display: flex;
  position: sticky;
  top: 0;
  border-bottom: 1px solid $grey-lighter;
}
</style>