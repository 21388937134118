import Vue from 'vue';
import Vuex from 'vuex';

import app from './modules/app';
import localStorage from './plugins/local-storage';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
  },
  plugins: [
    localStorage,
  ],
  strict: true,
});
