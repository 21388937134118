import { auth } from '@/services/firebase';

export default (to, from, next) => {
  const currentUser = auth.currentUser;
  if (!currentUser) {
    if (to.name === 'login') {
      next();
    } else {
      next({ name: 'login' });
    }
  } else {
    if (to.name === 'login') {
      next({ name: 'pull-requests' })
    } else {
      next();
    }
  }
};