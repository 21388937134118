<template lang="pug">
  b-field(
    :label="label"
    :label-position="labelPosition"
    :class="{ 'required': required }"
    :grouped="grouped"
    )
    b-select(
      :icon="icon"
      :icon-pack="iconPack"
      v-model="selectValue"
      :expanded="expanded"
      :placeholder="placeholder"
      :class="{ 'is-empty': !selectValue, 'selected': value }"
      :size="size"
      expanded
      :rounded="rounded"
      @input="updateParent"
      )
      option(:hidden="!allowEmpty" value) {{ placeholder }}
      option(
        v-for="(option, index) in options"
        :value="option"
        :key="index"
        ) {{ optionLabelKey ? option[optionLabelKey] : option }}
    slot(name="grouped-content")
</template>

<script>
export default {
  name: 'PSelect',
  props: {
    allowEmpty: {
      type: Boolean,
      default: false,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    grouped: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    iconPack: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    optionLabelKey: {
      type: String,
      default: '',
    },
    labelPosition: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      default: 'Select...',
    },
    required: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Object],
      default: '',
    },
  },
  data() {
    return {
      selectValue: '',
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.selectValue = value;
      },
    },
  },
  methods: {
    updateParent() {
      const newValue = this.selectValue || '';
      this.$emit('input', newValue);
    },
  },
};
</script>
