import $store from '@/store';

export default [
  {
    path: '/',
    name: 'pull-requests',
    component: () => import('@/views/PullRequestsPage.vue'),
  },
  {
    path: '/leaderboard',
    name: 'leaderboard',
    component: () => import('@/views/LeaderboardPage.vue'),
  },
  {
    path: '/user-profile',
    name: 'user-profile',
    component: () => import('@/views/UserProfilePage.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/LoginPage.vue'),
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('@/views/AdminPage.vue'),
    beforeEnter: (to, from, next) => {
      if ($store.getters.isAdminUser) {
        next();
      } else {
        next('/'); 
      }
    }
  },
];