<template lang="pug">
  b-modal(
    v-model="isCardModalActive"
    has-modal-card
    trap-focus
    aria-role="dialog"
    aria-label="Feedback Modal"
    @close="$emit('close')"
    )
    .modal-card
      header.modal-card-head
        p.modal-card-title Send Feedback
        button.delete(type="button" @click="$emit('close')")
      section.modal-card-body
        b-field(label="Type")
          p-select(
            v-model="feedbackForm.type"
            :options="types"
            required
            expanded
            )
        b-field(label="Description")
          b-input(
            v-model="feedbackForm.description"
            type="textarea"
            required
            )
      footer.modal-card-foot
        b-button(label="Close" @click="$emit('close')")
        b-button(
          label="Submit"
          type="is-primary"
          :disabled="!feedbackForm.description"
          @click="sendFeedback"
          )
</template>

<script>
import { db } from '@/services/firebase';

export default {
  name: 'FeedbackModal',
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isCardModalActive: false,
      feedbackForm: {
        type: 'Feature Request',
        description: '',
      },
      types: [
        'Feature Request',
        'General Feedback',
        'Report a Bug',
      ],
    };
  },
  watch: {
    isActive(val) {
      this.isCardModalActive = val;
      if(!val) {
        this.feedbackForm = { type: 'Feature Request', description: '' };
      }
    },
    isCardModalActive(val) {
      this.$emit('isActive.sync', val);
    },
  },
  methods: {
    sendFeedback() {
      const createdDt = Date.now();
      db.collection('feedback').doc(`${createdDt}`)
        .set({
          ...this.feedbackForm,
          status: 'New',
          ticketLink: '',
          createdDt,
        })
        .then(() => {
          this.$emit('close');
          this.$buefy.snackbar.open({ message: 'Thank you for your feedback!', type: 'is-success', actionText: null });
        })
        .catch((e) => this.$buefy.snackbar.open({ message: `Failed to send feedback: ${e}`, duration: 5000, type: 'is-danger' }));
    },
  },
};
</script>
