import Vue from 'vue'
import Router from 'vue-router'

import routes from '@/router/routes';
import LoggedInMiddleware from '@/router/middleware/logged-in-middleware';

Vue.use(Router)

const router = new Router({
  routes,
});

router.beforeEach(LoggedInMiddleware);

export default router;